import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'ui-kit-ck-consultant/dist/index.css';
import 'ui-kit-ck-consultant/css/style.css';
import "./index.css";

console.log(
  `Name : ${process.env.REACT_APP_NAME}\nVersion : ${process.env.REACT_APP_VERSION}\nDate last version : ${process.env.REACT_APP_DATE}`
);

// Get the root element
const rootElement = document.getElementById('root');

// Create a React root
const root = ReactDOM.createRoot(rootElement);

// Render the app
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
